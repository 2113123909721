var api = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-194.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/string-replace-webpack-plugin/loader.js?id=3hiy0dnfwwa!../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-194.use[4]!../../../node_modules/vue-loader/dist/index.js??ruleSet[1].rules[9].use[0]!../../../node_modules/string-replace-webpack-plugin/loader.js?id=qombra9hhs!./TriggerPopup.vue?vue&type=style&index=0&id=1ad6a5a3&lang=scss&scoped=true");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {"injectType":"singletonStyleTag","attributes":{"id":"online-chat-root-styles"}};

options.insert = function insertAtTop(element) {
                                    var parent = document.querySelector('head');
                                    if (parent) {
                                        var firstElement = parent.firstElementChild;
                                        if (firstElement) {
                                            parent.insertBefore(element, firstElement);
                                        }
                                        else {
                                            parent.appendChild(element);
                                        }
                                    }
                                };
options.singleton = true;

var update = api(content, options);

var exported = content.locals ? content.locals : {};



module.exports = exported;